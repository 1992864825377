a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    font-weight: 600;
}

.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

input[type='checkbox'] {
    visibility: hidden;
}

.header {
    display: flex;
    flex: 0 0 40px;
    background-color: #3c3c3c;
    align-items: center;
}

.header__logo {
    height: 30px;
    margin: 4px;
}

.header__title {
    margin-left: 8px;
    font-weight: 300;
    font-size: 18px;
    flex: 1;
}

.header__subtitle {
    margin-left: 12px;
    font-weight: 300;
}

.header__button {
    display: flex;
    align-items: center;
    margin: 8px;
    padding: 4px;
    color: #ffffff;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 14px;
}

.header__button:hover {
    border: 1px solid #696969;
    text-decoration: none;
}

.header__button__icon {
    margin-right: 4px;
}

.content {
    display: flex;
    flex: 1;
}

.sidebar {
    flex: 0 0 300px;
    background-color: #252525;
    display: flex;
}

.sidebar__content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sidebar__footer {
    padding: 4px 8px;
    background-color: #333333;
    display: flex;
    align-items: center;
}

.about__me {
    display: flex;
    align-items: center;
}

.about__me svg {
    margin: 0 2px;
}

.about__me__social {
    opacity: .7;
    margin-left: auto;
    height: 13px;
}

.about__me__social:hover {
    opacity: 1;
}

.navigate {
    background-color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.navigate__icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigate__icon:hover {
    background-color: #444444;
    cursor: pointer;
}

.navigate__icon.selected {
    border-left: 3px solid #F5DF19;
}

.navigate__icon svg {
    width: 24px;
    height: 24px;
    margin: auto;
}

.fileList {
    flex: 1;
}

.settings {
    flex: 1;
}

.editors {
    flex: 4;
    height: calc(100vh - 43px);
    overflow: hidden;
    background: #252525;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #1e1e1e;
}

.info {
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: #252525;
    max-width: 500px;
}

.info__welcome {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 16px;
}

.info__welcome__title {
    font-size: 3em;
    font-weight: 600;
}

.info__welcome__subtitle {
    font-size: 1.6em;
}

.footer {
    display: flex;
    flex: 0 0 40px;
    align-items: center;
}

.footer__plug {
    margin-left: 8px;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.loader {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.benchmark-start__button {
    background-color: #0277CF;
    border: none;
    padding: 10px 30px;
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px auto 0 auto;
    cursor: pointer;
    transition: all .3s;
}

.benchmark-start__button--center {
    transform: translateY(25vh) scale(1.2);
    transition: all 0s;
}

.benchmark-start__button:hover {
    background-color: #1b84d3;
}