.editor {
  flex: 1;
  height: 100px;
}

.editor-tab {
  align-self: flex-start;
  display: flex;
  background-color: #1e1e1e;
  margin-top: 1px;
  padding: 8px 12px;
}
