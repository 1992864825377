@import url(https://fonts.googleapis.com/css?family=Lato);
html,
body {
  height: 100%;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body,
input {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3c3c3c;
}

input {
  padding-left: 2px;
  border: none;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.editor {
  -ms-flex: 1 1;
      flex: 1 1;
  height: 100px;
}

.editor-tab {
  -ms-flex-item-align: start;
      align-self: flex-start;
  display: -ms-flexbox;
  display: flex;
  background-color: #1e1e1e;
  margin-top: 1px;
  padding: 8px 12px;
}

.wrap-collabsible {
  margin: 8px 0;
}

.toggle {
  display: none;
}

.lbl-toggle--fast {
  background: #41b882 !important;
}

.lbl-toggle--slow {
  background: #D2663F !important;
}

.lbl-toggle {
  display: block;
  padding: 1rem;
  font-size: 14px;

  /* color: #000000; */
  background: #494949;
  /* border: 1px solid #61affe; */

  cursor: pointer;

  /* border-radius: 7px; */
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
}

.lbl-toggle::before {
  content: ' ';
  display: inline-block;
  -ms-flex: 0 0 20px;
      flex: 0 0 20px;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);

  -webkit-transition: -webkit-transform .2s ease-out;

  transition: -webkit-transform .2s ease-out;

  transition: transform .2s ease-out;

  transition: transform .2s ease-out, -webkit-transform .2s ease-out;
}

.toggle:checked + .lbl-toggle::before {
  -webkit-transform: rotate(90deg) translateX(-3px);
          transform: rotate(90deg) translateX(-3px);
}

.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  -webkit-transition: max-height .25s ease-in-out;
  transition: max-height .25s ease-in-out;
}

.toggle:checked + .lbl-toggle + .collapsible-content {
  max-height: 350px;
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
  background: #ffffff33;
  /* border-left: 1px solid #61affe;
  border-right: 1px solid #61affe;
  border-bottom: 1px solid #61affe; */
}

.language-javascript {
  margin: 0 !important;
}

.results {
  font-weight: 600;
}

.results__ops {
  float: right;
}

.results__loading {
  float: right;
}


ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filelist__item {
  padding: 4px 4px 4px 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.filelist__item__icon {
  width: 16px;
  height: 16px;
  -ms-flex: 0 0 16px;
      flex: 0 0 16px;
  margin-right: 4px;
}

.filelist__item__title {
  -ms-flex: 1 1;
      flex: 1 1;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  outline: none;
  border: none;
  color: #ffffff;
  background-color: transparent;
  margin-right: 4px;
}

.filelist__item__title:focus {
  background-color: #ffffff;
  color: #252525;
}

.filelist__item__version {
  color: #939393;
  font-size: 11px;
}

.filelist__item__error {
  color: #e50000;
  font-size: 11px;
}

.filelist__item__close {
  -ms-flex: 0 0 13px;
      flex: 0 0 13px;
  cursor: pointer;
}

.filelist__item__add {
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
}

.filelist__item__loading {
  height: 13px;
}

.filelist__item:hover {
  background-color: #313131;
}

.filelist__item__header {
  background-color: #494949;
  padding: 4px 8px;
  text-transform: uppercase;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}

.filelist__item__header__title {
  -ms-flex: 1 1;
      flex: 1 1;
}

.filelist__item__header__add {
  -ms-flex: 0 0 13px;
      flex: 0 0 13px;
}

.filelist__dependency__input {
  margin: 8px;
  padding: 4px;
  width: calc(100% - 24px);
}

.checkbox-label {
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: grey;
    display: block;
    position: relative;
}

.checkbox-label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #252525;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.checkbox:checked+.checkbox-label {
    background: #F5DF19;
}

.checkbox:checked+.checkbox-label:after {
    left: calc(100% - 2px);
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}

.settings__header {
    background-color: #494949;
    padding: 4px 8px;
    text-transform: uppercase;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.settings__subheader {
    padding: 8px 12px 0 12px;
    text-transform: uppercase;
    font-weight: 600;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.settings__line {
    padding: 4px 12px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
}

.settings__line__text {
    white-space: nowrap;
    padding-right: 8px;
}
a {
    color: #ffffff;
    text-decoration: none;
}

a:hover {
    font-weight: 600;
}

.app {
    height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

input[type='checkbox'] {
    visibility: hidden;
}

.header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 40px;
        flex: 0 0 40px;
    background-color: #3c3c3c;
    -ms-flex-align: center;
        align-items: center;
}

.header__logo {
    height: 30px;
    margin: 4px;
}

.header__title {
    margin-left: 8px;
    font-weight: 300;
    font-size: 18px;
    -ms-flex: 1 1;
        flex: 1 1;
}

.header__subtitle {
    margin-left: 12px;
    font-weight: 300;
}

.header__button {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    margin: 8px;
    padding: 4px;
    color: #ffffff;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px;
    font-size: 14px;
}

.header__button:hover {
    border: 1px solid #696969;
    text-decoration: none;
}

.header__button__icon {
    margin-right: 4px;
}

.content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1;
        flex: 1 1;
}

.sidebar {
    -ms-flex: 0 0 300px;
        flex: 0 0 300px;
    background-color: #252525;
    display: -ms-flexbox;
    display: flex;
}

.sidebar__content {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}

.sidebar__footer {
    padding: 4px 8px;
    background-color: #333333;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.about__me {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}

.about__me svg {
    margin: 0 2px;
}

.about__me__social {
    opacity: .7;
    margin-left: auto;
    height: 13px;
}

.about__me__social:hover {
    opacity: 1;
}

.navigate {
    background-color: #333333;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
}

.navigate__icon {
    width: 50px;
    height: 50px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.navigate__icon:hover {
    background-color: #444444;
    cursor: pointer;
}

.navigate__icon.selected {
    border-left: 3px solid #F5DF19;
}

.navigate__icon svg {
    width: 24px;
    height: 24px;
    margin: auto;
}

.fileList {
    -ms-flex: 1 1;
        flex: 1 1;
}

.settings {
    -ms-flex: 1 1;
        flex: 1 1;
}

.editors {
    -ms-flex: 4 1;
        flex: 4 1;
    height: calc(100vh - 43px);
    overflow: hidden;
    background: #252525;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    border-right: 1px solid #1e1e1e;
}

.info {
    -ms-flex: 3 1;
        flex: 3 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    background-color: #252525;
    max-width: 500px;
}

.info__welcome {
    -ms-flex: 1 1;
        flex: 1 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    text-align: center;
    margin: 16px;
}

.info__welcome__title {
    font-size: 3em;
    font-weight: 600;
}

.info__welcome__subtitle {
    font-size: 1.6em;
}

.footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 40px;
        flex: 0 0 40px;
    -ms-flex-align: center;
        align-items: center;
}

.footer__plug {
    margin-left: 8px;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.loader {
    border-top: 2px solid rgba(255, 255, 255, 0.2);
    border-right: 2px solid rgba(255, 255, 255, 0.2);
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    border-left: 2px solid #ffffff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.benchmark-start__button {
    background-color: #0277CF;
    border: none;
    padding: 10px 30px;
    font-size: 1.3em;
    color: #ffffff;
    margin: 5px auto 0 auto;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.benchmark-start__button--center {
    -webkit-transform: translateY(25vh) scale(1.2);
            transform: translateY(25vh) scale(1.2);
    -webkit-transition: all 0s;
    transition: all 0s;
}

.benchmark-start__button:hover {
    background-color: #1b84d3;
}
