ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.filelist__item {
  padding: 4px 4px 4px 12px;
  display: flex;
  align-items: center;
}

.filelist__item__icon {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
  margin-right: 4px;
}

.filelist__item__title {
  flex: 1;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  outline: none;
  border: none;
  color: #ffffff;
  background-color: transparent;
  margin-right: 4px;
}

.filelist__item__title:focus {
  background-color: #ffffff;
  color: #252525;
}

.filelist__item__version {
  color: #939393;
  font-size: 11px;
}

.filelist__item__error {
  color: #e50000;
  font-size: 11px;
}

.filelist__item__close {
  flex: 0 0 13px;
  cursor: pointer;
}

.filelist__item__add {
  width: 1.3em;
  height: 1.3em;
  cursor: pointer;
}

.filelist__item__loading {
  height: 13px;
}

.filelist__item:hover {
  background-color: #313131;
}

.filelist__item__header {
  background-color: #494949;
  padding: 4px 8px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.filelist__item__header__title {
  flex: 1;
}

.filelist__item__header__add {
  flex: 0 0 13px;
}

.filelist__dependency__input {
  margin: 8px;
  padding: 4px;
  width: calc(100% - 24px);
}
